import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let BetterCareLogo = (props) => (
	<SvgIcon {...props} viewBox={"0 0 542.01 116.85"}>
		<title>Better care</title>
		<g>
			<g>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					fill="#00A1F2"
					d="M22.35,83.71c0.05,0.73,0.51,0.44,0.69,0.3
			c2.93-2.43,10.62-4.58,16.91-4.5h16.97v0.03l0.36,0c5.61-0.07,9.26-2.11,11.44-4.95c2.4-3.12,3.39-7.35,3.39-11.3h0.03l0-0.32
			c0-0.18-0.02-0.55-0.06-1.14c-0.22-3.45-1.3-6.21-3.28-7.95c-2.2-1.94-5.78-3.02-10.87-2.95c-2.84,0.03-9.73-0.03-14.57,3.28
			c-2.24,1.53-2.47,0.23-2.17-1.11c1.64-7.26,5.01-9.02,7.38-14.27c0.65-1.1,1.13-2.36,1.42-3.7c0.55-2.56,0.41-5.32-0.51-7.79
			c-0.86-2.31-2.45-4.4-4.87-5.84c-1.27-0.75-2.79-1.35-4.56-1.72c-1.07-0.23-2.2-0.34-3.36-0.34c-3.28,0-6.44,0.98-8.92,2.85
			c-2.42,1.82-4.27,4.56-5.04,8.15c-0.25,1.18-0.39,2.52-0.39,4V83.71L22.35,83.71z M56.92,95.72H38.22c-8.83,0-25,5.22-30.51,17.16
			c-0.6,1.29-1.56,0.7-1.56-0.16V87.62v-53.2c0-2.55,0.27-5,0.77-7.35c1.64-7.65,5.75-13.63,11.18-17.71
			c5.36-4.03,11.94-6.14,18.61-6.14c2.19,0,4.45,0.24,6.72,0.72c3.57,0.76,6.71,2.01,9.43,3.62c5.83,3.46,9.68,8.52,11.77,14.13
			c1.66,4.46,2.18,9.24,1.68,13.8c5.36,1.04,9.75,3.18,13.2,6.22c5.37,4.73,8.22,11.31,8.72,19.1c0.03,0.43,0.05,1.1,0.06,1.97v0.06
			l0,0.42l0.03,0.03c0,7.07-1.96,14.89-6.75,21.11c-4.99,6.5-12.77,11.13-24.11,11.29h-0.06l-0.45,0L56.92,95.72L56.92,95.72z
			 M72.14,62.96V62.9V62.96z"
				/>
			</g>
			<g>
				<path
					fill="#222222"
					d="M119.26,19.25h25.39c2.78,0,5.38,0.44,7.8,1.31c2.42,0.88,4.52,2.09,6.3,3.63
			c1.78,1.54,3.19,3.34,4.22,5.4c1.03,2.06,1.54,4.26,1.54,6.62c0,3.45-0.83,6.33-2.49,8.66c-1.66,2.33-3.73,4.1-6.21,5.3v0.73
			c3.32,1.21,6,3.1,8.03,5.67c2.02,2.57,3.04,5.76,3.04,9.57c0,2.78-0.56,5.28-1.68,7.48c-1.12,2.21-2.65,4.1-4.58,5.67
			c-1.94,1.57-4.19,2.78-6.76,3.63c-2.57,0.85-5.34,1.27-8.3,1.27h-26.3V19.25z M143.75,45.73c2.84,0,5.03-0.77,6.57-2.31
			c1.54-1.54,2.31-3.37,2.31-5.49s-0.76-3.93-2.27-5.44c-1.51-1.51-3.6-2.27-6.26-2.27H131.5v15.51H143.75z M145.11,72.94
			c3.08,0,5.44-0.77,7.07-2.31s2.45-3.52,2.45-5.94c0-2.42-0.83-4.43-2.49-6.03c-1.66-1.6-4.13-2.4-7.39-2.4H131.5v16.69H145.11z"
				/>
				<path
					fill="#222222"
					d="M216.93,73.48c-2.06,3.63-4.82,6.56-8.3,8.8c-3.48,2.24-7.72,3.36-12.74,3.36c-3.39,0-6.52-0.59-9.39-1.77
			c-2.87-1.18-5.37-2.83-7.48-4.94c-2.12-2.12-3.76-4.61-4.94-7.48c-1.18-2.87-1.77-6.03-1.77-9.48c0-3.2,0.57-6.24,1.72-9.11
			c1.15-2.87,2.75-5.38,4.81-7.53c2.05-2.15,4.49-3.85,7.3-5.12c2.81-1.27,5.91-1.9,9.3-1.9c3.57,0,6.74,0.59,9.52,1.77
			c2.78,1.18,5.11,2.81,6.98,4.9c1.87,2.08,3.29,4.55,4.26,7.39c0.97,2.84,1.45,5.93,1.45,9.25c0,0.42,0,0.79,0,1.09
			c-0.06,0.36-0.09,0.7-0.09,1c-0.06,0.3-0.09,0.63-0.09,1h-33.46c0.24,1.81,0.74,3.37,1.5,4.67c0.76,1.3,1.69,2.39,2.81,3.26
			c1.12,0.88,2.34,1.51,3.67,1.9c1.33,0.39,2.69,0.59,4.08,0.59c2.72,0,4.97-0.62,6.76-1.86c1.78-1.24,3.19-2.8,4.22-4.67
			L216.93,73.48z M206.14,56.25c-0.06-0.79-0.32-1.66-0.77-2.63c-0.45-0.97-1.12-1.87-2-2.72c-0.88-0.85-1.97-1.54-3.26-2.09
			c-1.3-0.54-2.86-0.82-4.67-0.82c-2.54,0-4.78,0.73-6.71,2.18c-1.94,1.45-3.3,3.48-4.08,6.08H206.14z"
				/>
				<path
					fill="#222222"
					d="M240.69,67.14c0,1.03,0.1,1.98,0.32,2.86c0.21,0.88,0.62,1.62,1.22,2.22c0.85,0.97,2.05,1.45,3.63,1.45
			c0.85,0,1.57-0.09,2.18-0.27c0.6-0.18,1.27-0.42,2-0.73l0.82,11.16c-1.21,0.42-2.34,0.71-3.4,0.86c-1.06,0.15-2.34,0.23-3.85,0.23
			c-2.24,0-4.25-0.35-6.03-1.04c-1.78-0.69-3.28-1.65-4.49-2.86c-2.84-2.72-4.26-6.59-4.26-11.61v-19.5h-7.8V39.75h7.8v-13.6h11.88
			v13.6h17.05v-13.6h11.88v13.6h10.88v10.16h-10.88v17.23c0,1.03,0.1,1.98,0.32,2.86c0.21,0.88,0.62,1.62,1.22,2.22
			c0.85,0.97,2.05,1.45,3.63,1.45c1.03,0,1.84-0.1,2.45-0.32c0.6-0.21,1.18-0.5,1.72-0.86l3.36,10.43c-1.39,0.67-2.89,1.17-4.49,1.5
			c-1.6,0.33-3.37,0.5-5.31,0.5c-2.24,0-4.25-0.35-6.03-1.04c-1.78-0.69-3.28-1.65-4.49-2.86c-2.84-2.72-4.26-6.59-4.26-11.61v-19.5
			h-17.05V67.14z"
				/>
				<path
					fill="#222222"
					d="M330.83,73.48c-2.06,3.63-4.82,6.56-8.3,8.8c-3.48,2.24-7.72,3.36-12.74,3.36c-3.39,0-6.51-0.59-9.39-1.77
			c-2.87-1.18-5.37-2.83-7.48-4.94c-2.12-2.12-3.76-4.61-4.94-7.48c-1.18-2.87-1.77-6.03-1.77-9.48c0-3.2,0.57-6.24,1.72-9.11
			c1.15-2.87,2.75-5.38,4.81-7.53c2.05-2.15,4.49-3.85,7.3-5.12c2.81-1.27,5.91-1.9,9.29-1.9c3.57,0,6.74,0.59,9.52,1.77
			c2.78,1.18,5.11,2.81,6.98,4.9c1.87,2.08,3.29,4.55,4.26,7.39c0.97,2.84,1.45,5.93,1.45,9.25c0,0.42,0,0.79,0,1.09
			c-0.06,0.36-0.09,0.7-0.09,1c-0.06,0.3-0.09,0.63-0.09,1h-33.46c0.24,1.81,0.74,3.37,1.5,4.67c0.75,1.3,1.69,2.39,2.81,3.26
			c1.12,0.88,2.34,1.51,3.67,1.9c1.33,0.39,2.69,0.59,4.08,0.59c2.72,0,4.97-0.62,6.76-1.86c1.78-1.24,3.19-2.8,4.22-4.67
			L330.83,73.48z M320.04,56.25c-0.06-0.79-0.32-1.66-0.77-2.63c-0.45-0.97-1.12-1.87-2-2.72c-0.88-0.85-1.97-1.54-3.26-2.09
			c-1.3-0.54-2.86-0.82-4.67-0.82c-2.54,0-4.78,0.73-6.71,2.18c-1.94,1.45-3.3,3.48-4.08,6.08H320.04z"
				/>
				<path
					fill="#222222"
					d="M339.17,39.75h11.15v6.17h0.73c0.54-1.09,1.27-2.09,2.18-2.99c0.91-0.91,1.92-1.7,3.04-2.37
			c1.12-0.67,2.33-1.22,3.63-1.63c1.3-0.41,2.58-0.62,3.85-0.62c1.57,0,2.92,0.17,4.04,0.5c1.12,0.34,2.07,0.77,2.86,1.31
			l-3.17,10.61c-0.73-0.36-1.53-0.65-2.4-0.86c-0.88-0.21-1.95-0.32-3.22-0.32c-1.63,0-3.11,0.32-4.44,0.96
			c-1.33,0.64-2.46,1.53-3.4,2.69c-0.94,1.16-1.66,2.51-2.18,4.06c-0.51,1.55-0.77,3.24-0.77,5.06v21.88h-11.88V39.75z"
				/>
				<path
					fill="#222222"
					d="M415.26,72.94c-0.79,1.81-1.77,3.49-2.95,5.03s-2.55,2.87-4.13,3.99c-1.57,1.12-3.39,2.01-5.44,2.67
			c-2.06,0.67-4.32,1-6.8,1c-3.21,0-6.17-0.61-8.89-1.81c-2.72-1.21-5.06-2.87-7.03-4.99c-1.97-2.12-3.51-4.61-4.62-7.48
			c-1.12-2.87-1.68-6-1.68-9.39c0-3.39,0.56-6.51,1.68-9.39c1.12-2.87,2.66-5.36,4.62-7.48c1.96-2.12,4.31-3.78,7.03-4.99
			c2.72-1.21,5.68-1.81,8.89-1.81c2.48,0,4.73,0.32,6.76,0.95c2.02,0.63,3.84,1.53,5.44,2.68c1.6,1.15,2.98,2.48,4.13,3.99
			c1.15,1.51,2.08,3.17,2.81,4.99l-5.17,2.27c-1.33-3.08-3.17-5.46-5.53-7.12c-2.36-1.66-5.26-2.49-8.71-2.49
			c-2.06,0-4.05,0.44-5.99,1.32c-1.94,0.88-3.66,2.12-5.17,3.72c-1.51,1.6-2.72,3.54-3.63,5.8c-0.91,2.27-1.36,4.79-1.36,7.57
			c0,2.78,0.45,5.3,1.36,7.57c0.91,2.27,2.12,4.2,3.63,5.8c1.51,1.6,3.23,2.84,5.17,3.72c1.93,0.88,3.93,1.32,5.99,1.32
			c3.45,0,6.38-0.83,8.8-2.49c2.42-1.66,4.29-4.04,5.62-7.12L415.26,72.94z"
				/>
				<path
					fill="#222222"
					d="M452.71,76.3h-0.36c-1.51,2.6-3.61,4.81-6.3,6.62c-2.69,1.81-5.82,2.72-9.39,2.72
			c-2.42,0-4.66-0.38-6.71-1.13c-2.06-0.75-3.84-1.78-5.35-3.08c-1.51-1.3-2.69-2.86-3.54-4.67c-0.85-1.81-1.27-3.78-1.27-5.89
			c0-2.42,0.5-4.56,1.5-6.44c1-1.87,2.36-3.45,4.08-4.72s3.72-2.22,5.99-2.86c2.27-0.63,4.67-0.95,7.21-0.95
			c3.02,0,5.74,0.35,8.16,1.04c2.42,0.7,4.41,1.5,5.99,2.4v-2.72c0-2-0.36-3.81-1.09-5.44c-0.73-1.63-1.69-3.02-2.9-4.17
			c-1.21-1.15-2.65-2.02-4.31-2.63c-1.66-0.6-3.4-0.91-5.21-0.91c-2.72,0-5.14,0.59-7.25,1.77c-2.12,1.18-3.87,2.89-5.26,5.12
			l-5.08-3.17c1.75-2.72,4.14-4.88,7.16-6.48c3.02-1.6,6.44-2.4,10.25-2.4c2.96,0,5.62,0.44,7.98,1.32
			c2.36,0.88,4.38,2.12,6.08,3.72c1.69,1.6,2.99,3.51,3.9,5.71c0.91,2.21,1.36,4.67,1.36,7.39v27.75h-5.62V76.3z M452.71,64.42
			c-1.81-1.09-3.79-1.98-5.94-2.68c-2.15-0.69-4.58-1.04-7.3-1.04c-2,0-3.82,0.24-5.49,0.72c-1.66,0.49-3.11,1.17-4.35,2.04
			c-1.24,0.88-2.21,1.95-2.9,3.22c-0.7,1.27-1.04,2.69-1.04,4.26c0,1.45,0.32,2.77,0.95,3.95c0.63,1.18,1.5,2.18,2.58,2.99
			c1.09,0.82,2.31,1.45,3.67,1.9s2.77,0.68,4.22,0.68c2,0,3.93-0.42,5.8-1.27c1.87-0.85,3.54-2.01,4.99-3.49
			c1.45-1.48,2.61-3.19,3.49-5.12C452.27,68.65,452.71,66.59,452.71,64.42z"
				/>
				<path
					fill="#222222"
					d="M468.4,84.18V39.75h5.44v7.8h0.36c0.42-1.33,1.1-2.55,2.04-3.67c0.94-1.12,2-2.09,3.17-2.9
			c1.18-0.82,2.42-1.47,3.72-1.95c1.3-0.48,2.55-0.73,3.76-0.73c1.39,0,2.54,0.11,3.45,0.31c0.91,0.21,1.78,0.56,2.63,1.05
			l-1.81,5.26c-1.21-0.6-2.87-0.91-4.99-0.91c-1.57,0-3.08,0.38-4.53,1.14s-2.72,1.81-3.81,3.14c-1.09,1.34-1.97,2.93-2.63,4.78
			c-0.67,1.85-1,3.87-1,6.06v25.05H468.4z"
				/>
				<path
					fill="#222222"
					d="M534.96,73.67c-0.79,1.63-1.78,3.17-2.99,4.62c-1.21,1.45-2.62,2.72-4.22,3.81
			c-1.6,1.09-3.43,1.95-5.49,2.58c-2.06,0.63-4.32,0.95-6.8,0.95c-3.21,0-6.17-0.59-8.89-1.77s-5.06-2.83-7.03-4.94
			c-1.97-2.12-3.49-4.61-4.58-7.48c-1.09-2.87-1.63-6.03-1.63-9.48c0-3.2,0.5-6.24,1.5-9.11c1-2.87,2.43-5.38,4.31-7.53
			c1.87-2.15,4.14-3.85,6.8-5.12c2.66-1.27,5.65-1.9,8.98-1.9c3.2,0,6.09,0.56,8.66,1.68c2.57,1.12,4.76,2.69,6.57,4.72
			c1.81,2.03,3.22,4.46,4.22,7.3c1,2.84,1.5,5.99,1.5,9.43c0,0.18,0,0.36,0,0.54c-0.06,0.18-0.09,0.33-0.09,0.45v0.54h-36.55
			c0.06,3.08,0.6,5.71,1.63,7.89c1.03,2.18,2.33,3.98,3.9,5.4c1.57,1.42,3.32,2.46,5.26,3.13c1.93,0.67,3.81,1,5.62,1
			c3.75,0,6.71-0.86,8.89-2.58c2.18-1.72,3.99-3.91,5.44-6.57L534.96,73.67z M529.8,58.07c-0.06-1.45-0.36-3.02-0.91-4.72
			c-0.54-1.69-1.39-3.26-2.54-4.71c-1.15-1.45-2.68-2.66-4.58-3.63c-1.9-0.97-4.25-1.45-7.03-1.45c-1.81,0-3.57,0.32-5.26,0.95
			s-3.22,1.56-4.58,2.77c-1.36,1.21-2.51,2.72-3.45,4.53c-0.94,1.81-1.56,3.9-1.86,6.26H529.8z"
				/>
			</g>
		</g>
	</SvgIcon>
);

BetterCareLogo = memo(BetterCareLogo);
BetterCareLogo.displayName = "BetterCareLogo";
BetterCareLogo.muiName = "BetterCareLogo";

export default BetterCareLogo;
